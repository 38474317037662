<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t('bank.head') }}</h2>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('bank.head_list3') }} </v-card-title>
        <v-card-actions class="d-flex align-center">
          <v-col cols="12" md="4">
            <v-row class="d-flex align-center ma-0 mt-3 text-capitalize" v-for="(i,n) in paymentMethodList" :key="n">
              <v-col cols="6" class="d-flex align-center pa-0">
                <span style="color: rgba(94, 86, 105, 0.87); font-weight: 600">{{paymentMethodList[n].title}}</span>
              </v-col>
              <v-col cols="6" class="pa-0">
                <v-switch
                  v-model="paymentMethodList[n].value"
                  color="success"
                  :label="paymentMethodList[n].value ? $t('enable') : $t('disable')"
                  class="ma-0 pa-0"
                  @change="changePaymentStatus(paymentMethodList[n])"
                  hide-details
                  inset >
                </v-switch>
              </v-col>
            </v-row>
          </v-col>
        </v-card-actions>

        <v-divider />

        <v-card-title> {{ $t('bank.head_list') }} </v-card-title>
        <v-card-actions class="pb-0 d-flex align-end">
          <v-spacer />
          <v-btn color="primary" class="px-5" @click="$router.push('/bank/bank_form_create')">
            <v-icon class="px-3">mdi-plus</v-icon>
            <span class="pr-3">{{ $t('bank.create') }}</span>
          </v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-spacer />
          <span class="px-3 mt-5">{{ $t('search') }}:</span>
          <div style="width: 150px" class="mt-5">
            <v-text-field v-debounce:500ms="searchKeyword" :date="searchData" outlined dense hide-details />
          </div>
        </v-card-actions>
        <v-card-actions class="pt-0">
          <v-data-table mobile-breakpoint="0" style="width: 100%" :headers="headers" :options.sync="pagination"
            :server-items-length="pagination.totalItems" :items-per-page="pagination.rowsPerPage" :loading="loading"
            :search="search" :page.sync="pagination.page" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }" :items="itemsData" :no-data-text="$t('not_found')">
            <template v-slot:[`item.bank`]="{ item }">
              <div class="w-100 ma-auto" style="width:25px;aspect-ratio:1;">
                <div v-if="!item.bankCountry">
                  <img class="pt-3" v-if="item.bank" alt="Avatar" width="25px"
                    :src="require(`@/assets/images/logos/banks/${item.bank}.png`)" />
                </div>
                <div v-else>
                  <img class="pt-3" v-if="item.bank" alt="Avatar" width="25px"
                    :src="`https://www.ak47.services/akasset-v2/banks/${item.bankCountry}/${item.bank}.webp`" />
                </div>
              </div>
              <span>{{ item.bankAccount }}</span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-select v-model="item.status" @change="changeValue(item)" :items="statusItems" outlined dense
                hide-details />
            </template>
            <template v-slot:[`item.statusWithDraw`]="{ item }">
              <v-select v-model="item.statusWithDraw" @change="changeValue(item)" :items="statusItems" outlined dense
                hide-details />
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu transition="slide-y-transition" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="success" dark v-bind="attrs" v-on="on">
                    <v-icon small color="white">mdi-square-edit-outline</v-icon>
                    <span class="px-3 white--text">{{ $t('bank.manage') }}</span>
                    <v-icon small color="white">mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <!-- <v-list-item  @click="$router.push(`/bank/bank_form_update/${item.id}`)">
                    <v-list-item-title>
                      <v-icon small color="warning">mdi-square-edit-outline</v-icon>
                      <span class="px-4">แก้ไข</span>
                    </v-list-item-title>
                  </v-list-item> -->
                  <!-- <v-divider /> -->
                  <v-list-item @click="deleteAccountBank(item)">
                    <v-list-item-title>
                      <v-icon small color="error">mdi-delete</v-icon>
                      <span class="px-4">{{ $t('delete') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import moment from 'moment'
import store from '@/store'
export default {
  components: {
    DatePickerInput,
    SelectInput,
  },

  data() {
    return {
      menu: false,
      showDetail: false,
      toggle_exclusive: 0,
      search: '',
      dateFrom: moment().subtract(3, 'days').format('YY-MM-DD'),
      dateTo: moment().format('YY-MM-DD'),
      date: null,
      currentMemberDetail: {},
      searchData: null,
      headers: [
        {
          text: this.$t('bank.header1'),
          value: 'bank',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('bank.header2'),
          value: 'bankName',
          width: '100px',
          align: 'center',
          sortable: false,
        },

        // {
        //   text: 'เลขบัญชี',
        //   value: 'bankAccount',
        //   width: '100px',
        //   align: 'center',
        //   sortable: false,
        // },

        {
          text: this.$t('bank.header3'),
          value: 'username',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('bank.headerType'),
          value: 'bankType',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('bank.header4'),
          value: 'timeWithdraw',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('bank.header5'),
          value: 'limitWithdraw',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('bank.header6'),
          value: 'status',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('bank.header7'),
          value: 'statusWithDraw',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('bank.header8'),
          value: 'actions',
          width: '95px',
          align: 'center',
          sortable: false,
        },
      ],
      statusItems: [
        {
          text: this.$t('open'),
          value: true,
        },
        {
          text: this.$t('close'),
          value: false,
        },
      ],
      itemsData: [],
      loading: true,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
      featureEnabled: [],
      paymentMethodList: [],
      enabledMobileBanking: false,
      enabledQrPromptpay: false,
      enabledQrPromptpay_sms: false,
      bankSystem: ''
    }
  },
  async created() {
    this.getWebSettingInfo()
    this.getWebSetting()
    this.addLogPage()
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        if (cont) await this.getBank(newValue.page, newValue.itemsPerPage, this.searchData)
      },
      deep: true,
    },
  },
  methods: {
    async getWebSettingInfo() {
      try {
        let settingWebInfo = await this.$store.dispatch('getSettingWebInfo')
        this.bankSystem = settingWebInfo?.bank_system[0] || 'TH';
      } catch (e) { }
    },
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch("https://jsonip.com/").then(res => res.json().then(el => (
          data = {
            ip: el.ip,
            name: 'ตั้งค่าธนาคาร',
            url: window.location.href,
            detail: 'ระบบจัดการธนาคาร',
            admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
          }
        )))
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async getWebSetting(){
      try {
        let res = await this.$store.dispatch('getSettingFeature')
        this.featureEnabled = res;
        const filterPaymentMethod = this.featureEnabled.filter(item => item['paymentMethod'])
        if (filterPaymentMethod && filterPaymentMethod.length > 0) {
          this.paymentMethodList = filterPaymentMethod;
        }
      } catch(e) {}
    },
    async getBank(page, row, search) {
      this.loading = true
      this.itemsData = []
      const rs = await this.$store.dispatch('getAccountBank', {
        // page: page,
        // rows: row,
        ...(!search ? { page } : {}),
        ...(!search ? { rows: row } : {}),
        ...(search ? { search } : {}),
      })
      this.pagination.totalItems = rs.count
      rs.data.forEach(element => {
        let splitCountry, splitBankCode;
        if (element?.bank_code) {
          const code = element.bank_code;
          const checkCountryCode = (code) => {
            const validCodes = ['TH', 'KR', 'LA', 'TW', 'CN', 'KH', 'VN', 'MM'];
            const firstTwoChars = code.substring(0, 2);
            return validCodes.includes(firstTwoChars);
          }

          if (checkCountryCode(code)) {
            splitCountry = code.slice(0, 2);
            splitBankCode = code.slice(2);
          } else {
            splitCountry = this.bankSystem;
            splitBankCode = code;
          }

          element.bankCountry = splitCountry.toLowerCase();
          element.bank = splitBankCode;
        }

        this.itemsData.push({
          id: element.id,
          bank: element.bank,
          bankName: element.bank_name,
          bankAccount: element.bank_account,
          bankCountry: element.bankCountry,
          bankType: element.bank_type,
          username: element.username,
          timeWithdraw: `${element.time_from}-${element.time_to}`,
          limitWithdraw: element.limit_withdraw,
          password: element.password,
          status: element.use ? true : false,
          statusWithDraw: element.use_withdraw ? true : false,
        })
      })
      this.loading = false
    },
    async changeValue(item) {
      try {
        // const myArray = timeWithdraw.split(" ");
        const params = {
          id: item.id,
          use: item.status,
          use_withdraw: item.statusWithDraw,
        }
        await this.$store.dispatch('updateAccountBank', params)
      } catch (e) {
        if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
          this.$swal.fire({
            icon: 'error',
            title: e.message,
          })
        }
      }
      await this.getBank(1, 25)
    },
    async deleteAccountBank(item) {
      this.$swal
        .fire({
          icon: 'warning',
          title: this.$t('alert'),
          text: `${this.$t('alert_list.list11')} ${item.bank}:${item.bankName}`,
          showCancelButton: true,
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
        })
        .then(async result => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            await this.$store.dispatch('deleteAccountBank', item.id)
            this.$swal.fire(this.$t('alert_list.list12'), '', 'success')
            await this.getBank(1, 25)

            // this.pagination.totalItems = result.count
            // this.value = result.data
            this.loading = false
          }
        })
    },
    async searchKeyword(value) {
      this.searchData = value
      await this.getBank(1, this.pagination.itemsPerPage, value)
      this.pagination.page = 1
    },
    async changePaymentStatus(value){
      for (let item of this.paymentMethodList) {
        for (let payment of this.featureEnabled) {
          if (item.paymentMethod === value.paymentMethod) {
            item.value = value.value ? 1 : 0;
          }
        }
      }

      const data = this.featureEnabled
      try {
        await this.$store.dispatch('postSettingFeature', data)
        this.$swal.fire({
          icon: 'success',
          title: this.$t('update_success'),
        })
      } catch (error) {
        this.$swal.fire({
          icon: 'error',
          title: this.$t('update_fail'),
        })
      } finally {
        this.loading = false
      }
    }
  },
}
</script>

<style>
  .v-input__control {
    margin-left: 1rem;
  }
</style>
